function onSquareClick(url, options = {}) {
    // window.innerWidth > 768 && 
    if ((options.forceIframe || (!url.includes('t.me') && !url.includes('youtu') && !url.includes('paypal') && !url.includes('rumble')))) {
        const content = document.getElementById("iframe-content");
        content.src = url;
        content.classList.remove('hidden');
        document.getElementById("center-column").classList.remove("hidden");
        if (window.innerWidth <= 768) {
            Array.prototype.forEach.call(document.getElementsByClassName("side-menu"), function(element) {
                element.classList.add("hidden");
            });
        }
    } else {
        window.open(url, '_blank');
    }

    const pageContent = document.getElementById("page-content");
        pageContent.style.height = '';
        document.body.scrollTop = 0;
    /* if (window.innerWidth > 768) {
        document.getElementById("page-header").classList.add('hidden');
        content.style.height = '92.5vh';
    } */

    fetch('/update?url=' + encodeURI(url));
}


class SideMenuItem extends BaseItem {
    constructor(data) {
        super(data);
        this.classList.add(
            'side-menu-square', 'overflow-hidden', 'relative',
            'block', 'h-[50vw]', 'md:h-[10vw]',
            'w-full', 'bg-zinc-400'
        );
    }

    connectedCallback() {
        if (!this.data) return;
        const imageContainer = document.createElement('image-container');
        imageContainer.classList.add('absolute', 'flex', 'h-full', 'w-full');
        const image = document.createElement('img');
        image.classList.add('square-image', 'transition-all', 'object-cover');
        image.src = this.data.image;
        if (this.data.darker_on_hover) {
            image.classList.add('darken-more');
        } if (!this.data.title && !this.data.description) {
            image.classList.add('dont-darken');
        } if (this.data.bw_on_hover) {
            image.classList.add('black-and-white');
        } if (this.data.hide_background) {
            image.classList.add('hide-background');
        }
        imageContainer.append(image);
        this.append(imageContainer);

        const itemTitle = document.createElement('square-title'); // 'side-menu-item-title');
        itemTitle.textContent = this.data.title;
        itemTitle.classList.add(
            'absolute', 'flex', 'flex-col', 'font-bold', 'text-white',
            'bottom-1', 'left-1', 'transition-opacity', 'font-sans'
        );
        if (this.data.font) {
            itemTitle.style.fontFamily = this.data.font;
            // console.log(this.data.font);
        }
        this.append(itemTitle);

        const itemDescription = document.createElement('square-description'); // 'side-menu-item-description');
        itemDescription.textContent = this.data.subtitle;
        itemDescription.classList.add('text-gray-300', 'font-normal', 'font-sans');
        itemTitle.append(itemDescription);
        
        // ?!
        if (this.data.title && this.data.title.toLowerCase() == 'pangea grandangolo') {
            this.id = "pangea-side";
            setGrandangoloLinks(this); // side-menu/link-grandangolo.js
        }
    }
};


window.customElements.define('side-menu-item', SideMenuItem);